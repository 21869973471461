<template>
<div>403 FORBIDDEN 无权限访问，请联系管理员咨询</div>
</template>

<script>
export default {

}
</script>

<style>

</style>